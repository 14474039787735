import React from 'react';
import { ProductCard_Product } from 'generated/graphql';
import { useStoreon } from 'hooks';
import { Settings } from 'react-slick';
import { ProductCard } from './ProductCard';
import { Carousel } from './Carousel';

type Props = {
  readonly products: readonly ProductCard_Product[];
};

const settings: Settings = {
  slidesToShow: 5,
  infinite: false,
  responsive: [
    {
      breakpoint: 1550,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1380,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 890,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export const Recommendations = ({ products }: Props) => {
  const { cartItems } = useStoreon('cartItems');
  const ids = cartItems.map((i) => i.productId);

  const productsWithoutCart = products.filter((prod) => ids.findIndex((id) => prod.id === id) < 0);

  if (productsWithoutCart.length === 0) return null;

  return (
    <div className="px-6 py-9 bg-orange-500 mt-6 lg:px-2 lg:py-3">
      <h2 className="mb-4 text-2xl uppercase font-medium lg:text-lg lg:mb-1 lg:ml-[4px]">Рекомендации</h2>
      <Carousel arrowStyles="top-[35%]" className="-mx-2" {...settings}>
        {productsWithoutCart.map((i) => (
          <div key={i.id} className="px-2">
            <ProductCard data={i} key={i.id} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
