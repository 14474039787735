import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Alert, Props as AlertProps } from './Alert';
import './AlertList.css';

type Props = {
  readonly items: readonly AlertProps[];
};

export const AlertList = ({ items }: Props) => (
  <TransitionGroup className="alert-list">
    {items.map((i) => (
      <CSSTransition key={i.message} timeout={300} classNames="alert-item">
        <div className="overflow-hidden">
          <Alert {...i} />
        </div>
      </CSSTransition>
    ))}
  </TransitionGroup>
);
