import * as React from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import qs from 'query-string';
import { useStoreon, useDryOrder, getExtraDryOrderData } from 'hooks';
import { captureException } from 'utils';
import { CatalogLayout } from 'components/CatalogLayout';
import { BackButton } from 'components/BackButton';
import { Price } from 'components/Price';
import CartEmptyIcon from 'components/icons/CartEmpty';
import { Recommendations } from 'components/Recommendations';
import { AlertList } from 'components/AlertList';
import { Loader } from 'components/Loader';
import { useUserResolver } from 'components/Auth';
import { CheckoutStage } from 'components/CheckoutStage';
import { PageError } from 'components/PageError';
import UploadIcon from 'components/icons/UploadIcon';
import { ProductCardList } from 'components/ProductCardList';

import { userClickButtonCheckout } from 'analytics';

const catalogLinkCn =
  'flex items-center h-10 font-medium text-orange-200 bg-orange-100 rounded-md uppercase tracking-wider px-8 mt-5 transition-opacity duration-300 ease-in-out hover:opacity-70';

const Empty = () => (
  <CatalogLayout>
    <div className="mt-5 w-full flex flex-col justify-center items-center" style={{ height: 'calc(100vh - 200px)' }}>
      <CartEmptyIcon size={161} />
      <p className="font-medium text-2xl mt-5 text-center">Ваша корзина пуста.</p>
      <p className="text-center">Перейдите в каталог – у нас там много вкусненького!</p>
      <Link to="/catalog" className={catalogLinkCn}>
        В Каталог
      </Link>
    </div>
  </CatalogLayout>
);

const Cart = ({ location }: PageProps) => {
  const { dispatch, cartItems } = useStoreon('cartItems');
  const addedIds = cartItems.map((i) => i.productId);
  const [queryRes, refetchQuery] = useDryOrder();
  const extra = getExtraDryOrderData(queryRes);
  const [getUser, signInModal] = useUserResolver();

  if (queryRes.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return (
      <CatalogLayout>
        <PageError error={queryRes.error} retry={retry} className="justify-center" />
      </CatalogLayout>
    );
  }

  if (queryRes.fetching && !queryRes.data) {
    return (
      <CatalogLayout>
        <Loader className="flex justify-center" />
      </CatalogLayout>
    );
  }

  if (extra.itemCount === 0) {
    return <Empty />;
  }

  // Данных может не быть, потому что pause: true из-за cart.addedIds.length === 0,
  // поэтому эта проверка должна быть выше
  if (!queryRes.data) {
    captureException(new Error(`data is null`));
    return null;
  }

  const res = queryRes.data.viewer.dryOrder;

  if (res.__typename !== 'PlaceOrderPayload') {
    if (res.__typename === 'ErrorPayload') {
      return <p>{res.message}</p>;
    }

    captureException(new Error(`unexpected __typename: ${JSON.stringify(res)}`));
    return null;
  }

  const clear = () => {
    // eslint-disable-next-line
    if (confirm('Вы уверены, что хотите очистить корзину?')) {
      dispatch('clearCart', null);
    }
  };

  const toCheckout = async () => {
    const user = await getUser();

    if (!user) {
      return;
    }

    if (user.addresses.length === 0) {
      dispatch('setModal', { type: 'createAddress', navigateTo: 'checkout' });
      return;
    }

    if (!user.activeAddress) {
      dispatch('setModal', { type: 'changeActiveAddress', navigateTo: 'checkout' });
      return;
    }

    userClickButtonCheckout();

    navigate('/checkout');
  };

  const products = res.order.items.map((i) => i.product);

  const productsInCart = products.filter((p) => addedIds.includes(p.id));

  const url = qs.stringify({ prods: btoa(JSON.stringify(cartItems)) });

  const handleShareCart = () => {
    dispatch('setModal', { type: 'shareCart', urlToSend: `${location.origin}/?${url}` });
  };

  return (
    <CatalogLayout>
      {signInModal}
      <div className="sticky z-[1] flex justify-between items-center bg-white py-[10px] top-[95px] sm:top-[120px]">
        <div className="flex bg-white">
          <BackButton />
          <div>
            <div className="flex items-center lg:items-baseline">
              <p className="text-2xl font-semibold mr-3 lg:text-base lg:mr-2">Корзина:</p>
              <Price value={res.order.itemSum} className="text-lg" />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {productsInCart.length && (
            <button type="button" className="mr-4 xs:hidden" title="Поделиться корзиной" onClick={handleShareCart}>
              <UploadIcon />
            </button>
          )}
          <button
            disabled={!extra.canPlace}
            className="btn-orange h-11 px-7 flex flex-initial items-center text-base text-center font-medium text-white rounded-md uppercase tracking-wide lg:text-sm lg:h-8 lg:px-2 lg:rounded-full disabled:bg-[#ccc] disabled:cursor-default"
            onClick={toCheckout}
            type="button"
          >
            Оформить
          </button>
        </div>
      </div>
      <div className="mt-5 lg:ml-0 relative lg:mt-2">
        <CheckoutStage step={1} />
        <AlertList items={res.alerts} />

        <div className="mt-4">
          <ProductCardList products={productsInCart} />
        </div>

        <div className="flex flex-col items-center flex-initial mt-10">
          <button
            type="button"
            disabled={!extra.canPlace}
            className="btn-orange h-14 px-7 flex flex-initial items-center text-base text-center font-medium text-white rounded-md uppercase tracking-wide disabled:bg-[#ccc] disabled:cursor-default"
            onClick={toCheckout}
          >
            Оформить покупку
          </button>
          <button
            type="button"
            className="h-10 px-7 mt-5 flex flex-initial items-center text-sm text-center text-gray-50 font-medium rounded-md uppercase tracking-wide duration-300 hover:text-black"
            onClick={clear}
          >
            очистить корзину
          </button>
        </div>
        {res.recommendedProducts.length > 0 && <Recommendations products={res.recommendedProducts} />}
      </div>
    </CatalogLayout>
  );
};

export default Cart;
